import { MantineProviderProps } from "@mantine/core"
import { Address } from "nodemailer/lib/mailer"

type BrandIDs = "canyouunlock.com"

export type Brand = {
  id: BrandIDs
  baseUrl: string
  staticDir: string
  staticUrl: string

  name: string
  title: string
  description: string

  slackNotifyMemberId: string

  emailSender: Address
  mailto: string

  accounting: {
    // Specify as strings to keep precision when turned into decimals
    unlockingFeeRate: string
    taxWithholdingRate: string
    advanceFeeRate: string
    accountCreationFee: string
    referralAmount: string
  }

  facebook: {
    id: string
    url: string
  }

  marketingStyleOverride: Omit<MantineProviderProps, "children">
  applicationStyleOverride: Omit<MantineProviderProps, "children">

  colors: {
    [key: string]: string
  }
}

const makeBrand = <T extends Brand>(brand: T) => brand

export const brands = {
  "canyouunlock.com": makeBrand({
    id: "canyouunlock.com",

    baseUrl: "https://www.canyouunlock.com",
    staticDir: "/static/cyu/",
    staticUrl: "https://www.canyouunlock.com/static/cyu/",

    name: "CanYouUnlock",
    title: "Can You Unlock your pension plan? | LIRA, LRSP, RPP, ...",
    description:
      "CanYouUnlock.com is Canada's expert in finding solutions that give people access to funds in their locked-in pension plans with a 95% success rate.",

    facebook: {
      id: "canyouunlock",
      url: "https://www.facebook.com/canyouunlock/",
    },

    slackNotifyMemberId: "U03SU3N5UCA", // Kyle

    emailSender: {
      name: "CanYouUnlock",
      address: "info@canyouunlock.com",
    },
    mailto: "info@canyouunlock.com",

    accounting: {
      // Specify as strings to keep precision when turned into decimals
      unlockingFeeRate: "0.0899",
      taxWithholdingRate: "0.3",
      advanceFeeRate: "0.1",
      accountCreationFee: "999",
      referralAmount: "250",
    },

    marketingStyleOverride: {
      theme: {
        fontFamily: "'Open Sans', sans-serif",
        headings: { fontFamily: "'Playfair Display', serif" },
        primaryColor: "cyan",
      },
      defaultProps: {
        Container: {
          sizes: {
            xs: 540,
            sm: 720,
            md: 972,
            lg: 1140,
            xl: 1320,
          },
        },
      },
    },

    applicationStyleOverride: { theme: { primaryColor: "cyan" } },

    colors: {
      yellow: "#f7b64d",
      lightyellow: "#fffbf6",
      darkblue: "#0a3c5f",
      cyan: "#59abc8",
    },
  }),
}

export const defaultBrand = "canyouunlock.com"

export const brand = brands[(process.env.BRAND as keyof typeof brands) ?? defaultBrand]
