import { AppProps } from "next/app"
import Head from "next/head"
import { MantineProvider } from "@mantine/core"
import { withTRPC } from "@trpc/next"
import type { AppRouter } from "~/trpc/router"
import superjson from "superjson"
import "~/trpc/extraTypes"
import "~/styles.css"
import { brand } from "~/brand"

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <title>{brand.title}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>

      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionOptions={{ key: "mantine", nonce: pageProps.nonce }}
      >
        <Component {...pageProps} />
      </MantineProvider>
    </>
  )
}

export default withTRPC<AppRouter>({
  config: () => ({
    url: "/api/trpc",
    transformer: superjson,
  }),
})(App)
