import { Temporal, toTemporalInstant } from "@js-temporal/polyfill"

if (process.env.NODE_ENV === "development" && typeof window !== "undefined")
  (window as any).Temporal = Temporal

Date.prototype.toTemporalInstant = toTemporalInstant

declare global {
  interface Date {
    toTemporalInstant: typeof toTemporalInstant
  }
}

export const toPlainDate = (legacyDate: Date): Temporal.PlainDate => {
  const instant = legacyDate.toTemporalInstant()
  const zoned = instant.toZonedDateTimeISO(Temporal.Now.timeZone())
  return zoned.toPlainDate()
}

export const toLegacyDate = (plainDate: Temporal.PlainDate) => {
  const timeZone = Temporal.Now.timeZone()
  const zoned = plainDate.toZonedDateTime({ timeZone })
  return new Date(zoned.epochMilliseconds)
}
