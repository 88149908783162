import { Temporal } from "@js-temporal/polyfill"
import superjson from "superjson"
import { z } from "zod"
import "~/Date"
import { Prisma } from "@prisma/client"

superjson.registerCustom<Temporal.Instant, string>(
  {
    isApplicable: (v): v is Temporal.Instant => v instanceof Temporal.Instant,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.Instant.from(v),
  },
  "Temporal.Instant"
)

superjson.registerCustom<Temporal.ZonedDateTime, string>(
  {
    isApplicable: (v): v is Temporal.ZonedDateTime => v instanceof Temporal.ZonedDateTime,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.ZonedDateTime.from(v),
  },
  "Temporal.ZonedDateTime"
)

superjson.registerCustom<Temporal.PlainDate, string>(
  {
    isApplicable: (v): v is Temporal.PlainDate => v instanceof Temporal.PlainDate,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.PlainDate.from(v),
  },
  "Temporal.PlainDate"
)

superjson.registerCustom<Prisma.Decimal, string>(
  {
    isApplicable: (v): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Prisma.Decimal(v),
  },
  "decimal.js"
)

// zod extras

const attempt = <F extends (...args: any) => any>(func: F): ReturnType<F> | false => {
  try {
    return func()
  } catch (err) {
    return false
  }
}

export const zx = {
  instant: () =>
    z
      .instanceof(Temporal.Instant)
      .refine((v) => attempt(() => Temporal.Instant.from(v)))
      .transform((v) => Temporal.Instant.from(v)),

  zonedDateTime: () =>
    z
      .instanceof(Temporal.ZonedDateTime)
      .refine((v) => attempt(() => Temporal.ZonedDateTime.from(v)))
      .transform((v) => Temporal.ZonedDateTime.from(v)),

  plainDate: () =>
    z
      .instanceof(Temporal.PlainDate)
      .refine((v) => attempt(() => Temporal.PlainDate.from(v)))
      .transform((v) => Temporal.PlainDate.from(v)),

  decimal: () =>
    z
      .instanceof(Prisma.Decimal)
      .or(z.string())
      .or(z.number())
      .refine((value) => {
        try {
          return new Prisma.Decimal(value)
        } catch (error) {
          return false
        }
      })
      .transform((value) => new Prisma.Decimal(value)),

  file: () =>
    z.object({
      fileName: z.string(),
      mimeType: z.string(),
    }),
}
